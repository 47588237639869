<template>
  <div class="edit-table">
    <slot name="toolbar"></slot>
    <el-form>
      <vxe-table  ref="fineTable" :data="paymentList">
        <vxe-table-column min-width="100" field="productCode" title="产品编码"></vxe-table-column>
        <vxe-table-column min-width="100" field="productName" title="产品名称"></vxe-table-column>
        <vxe-table-column min-width="100" field="boxCode" title="箱码"></vxe-table-column>
        <vxe-table-column min-width="100" field="dealerName" title="所属经销商"></vxe-table-column>
      </vxe-table>
    </el-form>
  </div>
</template>

<script>
import request from '@/found/utils/request';

export default {
  name: 'Payment',
  props: {
    value: Array,
  },
  watch: {
    value() {
      this.paymentList = this.value;
    },
  },
  data() {
    return {
      paymentList: [], // 支付明细
    };
  },

  methods: {

  },
};
</script>

<style lang="less" scoped>
.edit-table {
  padding-left: 50px;

}
.form-conrtol {
  width: 100%;
}
/deep/ .vxe-body--column {
  height: 50px !important;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
  color: white !important;
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-table" },
    [
      _vm._t("toolbar"),
      _c(
        "el-form",
        [
          _c(
            "vxe-table",
            { ref: "fineTable", attrs: { data: _vm.paymentList } },
            [
              _c("vxe-table-column", {
                attrs: {
                  "min-width": "100",
                  field: "productCode",
                  title: "产品编码",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  "min-width": "100",
                  field: "productName",
                  title: "产品名称",
                },
              }),
              _c("vxe-table-column", {
                attrs: { "min-width": "100", field: "boxCode", title: "箱码" },
              }),
              _c("vxe-table-column", {
                attrs: {
                  "min-width": "100",
                  field: "dealerName",
                  title: "所属经销商",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
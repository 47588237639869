<script>
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import DetailVoList from './detailVoList.vue';

formCreate.component('DetailVoList', DetailVoList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'remote_code_scanning_form',
      formParentCode: 'CRM20230613000000002',
      rule: [],
      buttons: {
        submit: false,
      },
    };
  },

  methods: {
    setRule(v) {
      const item = v;
      return item;
    },
    formComplete() {
      // 获取详情
      console.log('formConfig', this.formConfig);
      if (this.formConfig.row.id) {
        const requestUrl = '/cps/web/exceptionScan/dealer/findById';
        request.get(requestUrl, { id: this.formConfig.row.scanId || this.formConfig.row.id }).then((res) => {
          if (res.success) {
            const detailVoList = this.getRule('detailVoList'); // 活动明细列表
            detailVoList.value = res.result.detailVoList || [];
            this.setValue(res.result);
          }
        });
      }
    },
  },
};
</script>
